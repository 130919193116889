import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
  return (
    <>
      <HashLink
        className="text-2xl px-4 font-extrabold text-gray-700 hover:text-green-400"
        smooth
        to="/#about"
      >
        About
      </HashLink>
      <HashLink
        className="text-2xl px-4 font-extrabold text-gray-700 hover:text-green-400"
        smooth
        to="/#services"
      >
        Services
      </HashLink>
      {/* <HashLink
        className="text-2xl px-4 font-extrabold text-gray-700 hover:text-green-400"
        to="/#portfolio"
      >
        Portfolio
      </HashLink> */}
      <HashLink
        className="text-2xl px-4 font-extrabold text-gray-700 hover:text-green-400"
        to="/contact#contact"
      >
        Contact Us
      </HashLink>
    </>
  );
};

export default NavLinks;
