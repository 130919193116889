import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';

const NavBar = () => {
  const [top, setTop] = useState(!window.scrollY);
  const [isOpen, setisOpen] = React.useState(false);
  function handleClick() {
    setisOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <nav
      className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${
        !top && 'bg-white shadow-lg'
      }`}
    >
      <div className="flex flex-row h-16 items-center mt-8 max-w-[100vw]">
        <div className="flex-auto w-3/4 lg:w-1/2">
          <HashLink smooth to="/#">
            <h1 className="font-extrabold lg:text-5xl md:text-4xl text-3xl text-gray-700 ml-6 flex-wrap">
              T<span className="text-green-300">O</span> Consulting Engineers{' '}
              <span className="text-[16px]">LTD</span>
            </h1>
            <p className="font-extrabold lg:text-[40px] xl:text-gray-700 hidden">
              Structural Consultants
            </p>
          </HashLink>
        </div>
        <div className="">
          {' '}
          <div className="group flex flex-col items-center">
            <button className="lg:hidden text-gray-700" onClick={handleClick}>
              <svg
                className="h-10 w-10 fill-current mr-8"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                {isOpen && (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                  />
                )}
                {!isOpen && (
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  />
                )}
              </svg>
            </button>
            <div className="hidden space-x-3 lg:inline-block mr-5 ">
              <NavLinks />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center py-2 pt-5">
        <div className="group flex flex-col items-center">
          <div className="hidden space-x-6 lg:inline-block p-5  lg:hidden">
            <NavLinks />
          </div>

          <div
            className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-10 bg-white lg:hidden shadow-xl top-24 ${
              isOpen ? 'block' : 'hidden'
            } `}
          >
            <div className="flex flex-col space-y-6 ">
              <NavLinks />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
