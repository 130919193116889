import React from 'react';
import img from '../images/web.svg';
import image from '../images/image2.PNG';
import img2 from '../images/image4.PNG';
import img3 from '../images/image3.PNG';
import img4 from '../images/consultation.svg';
import { useDocTitle } from '../components/CustomHook';

const Services = () => {
  useDocTitle('TOC Engineers - Services');
  return (
    <div id="services" className="bg-gray-100 py-12 w-screen overflow-hidden">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-gray-800 uppercase font-bold">
            What We Do
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:border-4 hover:border-green-300 rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <h2 className="font-semibold my-4 text-3xl text-center">
                  Structural Calculations
                </h2>
                <p className="text-lg font-medium">
                  At TO Consulting Engineers, we produce structural calculations
                  for any type and scale of project, from internal wall
                  removals, extensions and loft conversions, to steel and
                  concrete frames. We have experience in design of all commonly
                  used building materials, including steel, concrete, timber,
                  masonry, including both elastic and plastic design.
                </p>
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mt-4"
                  src={image}
                />
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:border-4 hover:border-green-300 rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <h2 className="font-semibold my-4 text-3xl text-center">
                  Connection Design
                </h2>
                <p className="text-lg font-medium">
                  We design and detail steel to steel, steel to concrete and
                  timber connections. Single bespoke connections and detailing
                  to large scale connection designs for frames.
                </p>
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out mt-16"
                  src={img2}
                />
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:border-4 hover:border-green-300 rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <h2 className="font-semibold my-4 text-3xl text-center">
                  Structural Surveys
                </h2>
                <p className="text-lg font-medium">
                  Structural surveys and condition surveys. Peace of mind before
                  you purchase your next property. Advice on planning and
                  building control applications, permitted development etc.
                </p>
                <img />
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:border-4 hover:border-green-300 rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <h2 className="font-semibold my-4 text-3xl text-center">
                  Online Consultations
                </h2>
                <p className="text-lg font-medium">
                  Reach out and have a 1-1 discussion about your project.
                  Discuss the project requirements, feasability and what would
                  be required. This will give you the confidence going forward,
                  allowing you to communicate your ideas to architects and
                  builders. You will be under no obligation to take the project
                  futher with TO Consulting Ltd.
                </p>
                <img />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center mt-4">
              {/* <div className="text-green-300 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                  <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                </svg>
              </div> */}
              <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center ml-4 text-gray-800 pt-3">
                <h3 className="text-3xl font-bold">
                  We <span className="font-black">Design</span>
                </h3>
                <div>
                  <p className="mt-4 text-xl">
                    With over 8 years experience in structural analysis and
                    design and a solid understanding of the current and previous
                    codes of practice , we provide customized recommendations
                    and strategies deliver your project to the highest standard.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center ml-4 text-gray-800 pt-3">
              <h3 className="text-3xl font-bold">
                We <span className="font-black">Collaborate</span>
              </h3>
              <div>
                <p className="mt-4 text-xl">
                  We will collaborate with you and your team - architects,
                  builders and building inspectors to ensure co-ordination and
                  acceptance at all stages.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
