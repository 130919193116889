import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg2 from '../images/heroimage2.png';

const Hero = () => {
  return (
    <>
      <div
        className="hero"
        style={{ height: '100vh', width: '100wh', overflow: 'hidden' }}
      >
        {' '}
        <div>
          <NavBar />
        </div>
        <div className=" overflow-hidden mt-24 lg:mt-36">
          <div className="flex flex-col lg:flex-row justify-between text-center lg:text-left lg:mt-6">
            <div className="lg:w-1/2 lg:text-center">
              <h1 className="font-bold  pb-4 lg:text-3xl md:text-3xl lg:text-gray-700 invisible lg:visible">
                Consulting Structural Engineers
              </h1>
              <ul className="md:text-2xl text-2xl font-bold text-gray-400  mb-4">
                <li className="pl-8 pr-8 pb-2">
                  Building Control Calculations
                </li>
                <li className="pl-8 pr-8 pb-2">
                  Extensions, Dormers, Wall Removals
                </li>
                <li className="pl-8 pr-8 pb-2">Foundation Design</li>
                <li className="pl-8 pr-8 pb-2">Frame Design</li>
                <li className="pl-8 pr-8 pb-2">Connection Design </li>
                <li className="pl-8 pr-8 pb-2">Retaining Walls </li>
                <li className="pl-8 pr-8 pb-2">Structural Surveys </li>
              </ul>

              <div className="md:space-x-2 md:mb-8">
                <Link
                  to="/contact"
                  className="text-white bg-gray-700 hover:text-green-300 inline-flex items-center justify-center w-full px-4 py-3 my-4 text-lg font-bold shadow-lg rounded-lg w-1/2 sm:mb-0"
                >
                  Contact us
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
            <div className="w-1/2">
              <img
                alt="card img"
                className="invisible lg:visible"
                src={heroImg2}
                style={{ height: '75%' }}
                // style={{ maxWidth: '50vh' }}
                //   style={{ width: '460px', height: '460px' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
