import React from 'react';
import img3 from '../images/image1.PNG';
import { useDocTitle } from './CustomHook';
import { Link } from 'react-router-dom';

const Intro = () => {
  useDocTitle('TOC Engineers - About');
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2 md:w-2/3 flex flex-col lg:mx-4 justify-center">
            <img
              alt="card img"
              className="rounded-t float-right"
              src={img3}
              //   style={{ width: '460px', height: '460px' }}
            />
          </div>
          <div
            className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
            data-aos="zoom-in"
            data-aos-delay="500"
            style={{ marginTop: '100px' }}
          >
            <h3 className="text-3xl  text-gray-700 font-bold">
              We develop high quality, efficient, economic and safe designs with
              a focus on domestic and residential properties.
            </h3>
            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                We produce structural engineering drawings and calculations in
                accordance with British Standards, Eurocodes and Building
                Regulations. Experience in residential, commerical, industrial
                and education sectors.
              </p>
            </div>

            <div>
              <p className="my-3 text-xl text-gray-600 font-semibold">
                We are well versed in commerical structural engineering software
                such as Tekla Structural Designer, MasterSeries, Revit and
                AutoCAD.
              </p>
            </div>
            <div className="md:space-x-2 md:mb-8">
              <Link
                to="/contact"
                className="text-white bg-gray-700 hover:text-green-300 inline-flex items-center justify-center w-full px-4 py-3 my-4 text-lg font-bold shadow-lg rounded-lg w-1/2 sm:mb-0"
              >
                Contact us
                <svg
                  className="w-4 h-4 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
